var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("vue-good-table", {
    attrs: {
      columns: _vm.columns,
      rows: _vm.rows,
      theme: _vm.theme,
      "pagination-options": {
        enabled: true,
        perPageDropdownEnabled: false,
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "table-row",
          fn: function (props) {
            return [
              _vm._t("table-row", null, {
                data: props,
              }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }